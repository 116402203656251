import React from "react";
import ReactDOM from "react-dom";
import Traec from "traec";

export default class AddTracker extends React.Component {
  constructor(props) {
    super(props);

    this.addTracker = this.addTracker.bind(this);
  }

  addTracker(e) {
    e.preventDefault();
    let { projectId } = this.props;
    let fetch = new Traec.Fetch("project_tracker", "post", { projectId });
    fetch.updateFetchParams({ body: { name: "sustainability_tool" } });
    fetch.dispatch();
  }

  render() {
    return (
      <div className="text-center">
        <p>
          It looks like this project does not have a Metric reporting tree set up. Something may have gone wrong with
          your project setup. Please contact the site administrators to resolve this or try setting up a new project.
        </p>
        {/*<button className="btn btn-primary" onClick={this.addTracker}>Add a Metric Tree</button>*/}
      </div>
    );
  }
}
